import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button/button';
import Card from '../components/card/card';
import moment from 'moment';
import ContentfulRichText from '../components/contentful/ContentfulRichText';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulReport {
          edges {
            node {
              title
              slug
              publishDate
              color
              style
              unlisted
              introLede {
                json
              }
              thumbnail {
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `} render={data =>
      <Layout unsetSpacing>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'nowrap', flexDirection:'column', marginTop:'8rem'}}>
          {data.allContentfulReport.edges.sort((b,a)=> a.node.publishDate > b.node.publishDate ? 1 : -1).map((edge, index) => {
              const report = edge.node;
              if(!report.unlisted) {
                return(
                  <Card key={index} backgroundColor={report.color} reverse={index%2 === 0 ? true : false} cardStyle={report.style}>
                    <div style={{margin:`0 auto`}}>
                      <div style={{padding:`15px`}}>
                        <h3>{moment(report.publishDate).format('MMMM Do YYYY')}</h3>
                        <h2>{report.title}</h2>
                        <ContentfulRichText richTextJSON={report.introLede.json}/>
                        <div style={{display:'flex', alignItems:'flex-end', justifyContent:'flex-end', width:'100%'}}>
                          <Button
                            text="Read More"
                            url={`/${report.slug}`}
                            use_border={false}
                            theme="dark"
                            link={true}
                            line="line-expand"
                            new_tab={false}
                          />
                        </div>
                      </div>
                    </div>
                    <Img fluid={report.thumbnail.fluid} />
                  </Card>
                )
              }
              else {
                return(<div />)
              }
            }
          )}
        </div>
      </Layout>
    }
  />
)

export default IndexPage
